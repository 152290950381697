import * as React from 'react';
//import { graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Social from '../components/social';

import Layout from '../components/layout';
import { useQuery } from '../hooks/useQuery';

function Page({ pageContext }) {
  const data = useQuery();
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title || '',
    description: page.frontmatter.description || '',
    socialTitle: page.frontmatter.featuredAlt,
    imgLocation: `${data.site.siteMetadata.siteUrl}`,
    socialImg: page.frontmatter.featuredSocial?.publicURL,
    socialImgWidth:
      page.frontmatter.featuredSocial?.childImageSharp.original.width,
    socialImgHeight:
      page.frontmatter.featuredSocial?.childImageSharp.original.height,
    socialImgType: page.frontmatter.featuredSocial?.internal.mediaType,
  };
  const url = `${data.site.siteMetadata.siteUrl}`;

  console.log(meta);

  return (
    <Layout meta={meta}>
      <MDXRenderer>{page.body}</MDXRenderer>
      <Social url={url} />
    </Layout>
  );
}

export default Page;
