import * as React from 'react';
import './social.scss';
import facebookIcon from '../images/icon-facebook-white.svg';
import xIcon from '../images/icon-x-white.svg';

const Social = ({ url }) => {
  return (
    <div className="social-footer has-text-centered">
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}/`}
        target="_blank"
      >
        <span class="share-icon">
          <img src={facebookIcon} alt="Share to Facebook" />
        </span>
      </a>
      <a href={`https://twitter.com/intent/tweet?url=${url}/`} target="_blank">
        <span class="share-icon">
          <img src={xIcon} alt="Post to X" />
        </span>
      </a>
    </div>
  );
};

export default Social;
